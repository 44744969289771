import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { Line, Group, Text } from "react-konva";
import { IReportRoofEdge, IReportRoofPlane, IReportRoofPoint } from "../Model";

export const HEIGHT_DRAW_AREA = 530;
export const WIDTH_DRAW_AREA = 690;

export const getCorrectedPoints = (points: any[], width: number, height: number, margin: any) => {
  var pl = getMovedPointsToStart(points, margin);
  var scale = getScaleToDraw(pl, width, height);

  return getScaledPoints(pl, scale);
};

export const getScaleToDraw = (points: any[], width: number, height: number) => {
  // Pobieramy maksymalną szerokość rysunku
  const maxXPoints = Math.max(...points.map((o) => o.x));

  //Pobieramy maskymalną wysokość rysunku
  const maxYPoints = Math.max(...points.map((o) => o.y));

  const widthRatio = width / (maxXPoints + 5);
  const heightRatio = height / (maxYPoints + 5);

  const scale = Math.min(widthRatio, heightRatio);

  return scale;
};

export const getScaledPoints = (points: any[], scale: any) => {
  const result = [];
  for (const p of points) {
    result.push({ ...p, x: p.x * scale, y: p.y * scale });
  }

  return result;
};

export const getMovedPointsToStart = (points: any[], margin: any) => {
  const minX = Math.min(...points.map((o: any) => o.x));
  const minY = Math.min(...points.map((o: any) => o.y));
  let distToMoveX = -1 * (minX - margin);
  let distToMoveY = -1 * (minY - margin);

  const result = [];
  for (const p of points) {
    result.push({ ...p, x: p.x + distToMoveX, y: p.y + distToMoveY });
  }
  return result;
};

// Przełożenie punktów na kolekcję dla komponentu Line
const getPointsForLineComponent = (pointIds: any[], roofPoints: any[]) => {
  let arr = [];
  for (const id of pointIds) {
    const p = roofPoints.find((x) => x.id === id);
    if (p) {
      arr.push(p.x);
      arr.push(p.y);
    }
  }
  return arr;
};

// Lista punktów na podstawie ich ids
export const getFullPoints = (pointIds: any[], points: any[]) => {
  let arr = [];
  for (const id of pointIds) {
    const p = points.find((x) => x.id === id);
    arr.push(p);
  }

  return arr;
};

// Współrzędne środka połaci
export const getPointsForCenetrRoofPart = (pts: any[]) => {
  var first = pts[0],
    last = pts[pts.length - 1];
  if (first.x != last.x || first.y != last.y) pts.push(first);
  var twicearea = 0,
    x = 0,
    y = 0,
    nPts = pts.length,
    p1,
    p2,
    f;
  for (var i = 0, j = nPts - 1; i < nPts; j = i++) {
    p1 = pts[i];
    p2 = pts[j];
    f = p1.x * p2.y - p2.x * p1.y;
    twicearea += f;
    x += (p1.x + p2.x) * f;
    y += (p1.y + p2.y) * f;
  }
  f = twicearea * 3;
  return { x: x / f, y: y / f };
};

type Props = {
  plane: IReportRoofPlane;
  points: IReportRoofPoint[];
  edges: IReportRoofEdge[];
  basic: boolean;
};

const RoofPart: FC<Props> = ({ basic, plane, points, edges }) => {
  return (
    <Container>
      {plane &&
        points.length > 0 &&
        plane.regions?.map((region: any, index: number) => {
          return (
            <Group key={index}>
              <Line
                points={getPointsForLineComponent(region.pointIds, points)}
                fill={basic === false ? "#f5f5f5cc" : "#e0e0e0df"}
                closed={true}
                stroke="#6b6b6b"
                strokeWidth={basic === false ? 0 : 2}
                lineJoin="round"
                lineCap="round"
              />

              {basic === false && plane.angle < 90 && (
                <Text
                  fill="#4d4d4d"
                  fontSize={10}
                  x={getPointsForCenetrRoofPart(getFullPoints(region.pointIds, points)).x}
                  y={getPointsForCenetrRoofPart(getFullPoints(region.pointIds, points)).y}
                  text={`∢${plane.angle.toFixed(0)}°`}
                />
              )}
            </Group>
          );
        })}
    </Container>
  );
};
export default RoofPart;

const Container = styled(Group)``;
