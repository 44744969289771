import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { Line, Group, Text } from "react-konva";
import { Html } from "react-konva-utils";
import { getColorByEdgeType } from "../Helper";
import { Tag } from "antd";
import { IReportRoofEdge, IReportRoofPoint } from "../Model";

const getPointsForLineComponent = (pointIds: any[], points: any[]) => {
  let arr = [];
  for (const id of pointIds) {
    const p = points.find((x: any) => x.id === id);

    if (p) {
      arr.push(p.x);
      arr.push(p.y);
    }
  }
  return arr;
};

const midpoint = (point1: IReportRoofPoint, point2: IReportRoofPoint) => {
  const x1 = point1.x;
  const y1 = point1.y;
  const x2 = point2.x;
  const y2 = point2.y;

  const midX = (x1 + x2) / 2;
  const midY = (y1 + y2) / 2;

  return { x: midX, y: midY };
};

const getMiddlePoint = (p1Id: string, p2Id: string, roofPoints: IReportRoofPoint[]) => {
  const p1 = roofPoints.find((x: any) => x.id === p1Id);
  const p2 = roofPoints.find((x: any) => x.id === p2Id);

  if (!p1 || !p2) {
    return { x: 0, y: 0 };
  }

  return midpoint(p1, p2);
};

type Props = {
  points: IReportRoofPoint[];
  edge: IReportRoofEdge;
};

const Edge: FC<Props> = ({ edge, points }) => {
  return (
    <Container>
      {edge && points && (
        <Group>
          <Line
            points={getPointsForLineComponent([edge.startPointId, edge.endPointId], points)}
            closed={false}
            stroke={getColorByEdgeType(edge.type)}
            strokeWidth={2}
          />

          <Group
            x={getMiddlePoint(edge.startPointId, edge.endPointId, points || []).x}
            y={getMiddlePoint(edge.startPointId, edge.endPointId, points || []).y}
          >
            <Html>
              <Box color={getColorByEdgeType(edge.type)} style={{ color: "#ffffff" }}>
                {edge.type}
              </Box>
            </Html>
          </Group>
        </Group>
      )}
    </Container>
  );
};
export default Edge;

const Container = styled(Group)``;

const Box = styled(Tag)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  padding: 0px 0px;
  font-size: 8px;
  line-height: 12px;
`;
