import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { Line, Group, Text } from "react-konva";
import { IReportDormerHole, IReportRoofEdge, IReportRoofPoint, IReportWindowHole } from "../Model";
import {
  getCorrectedPoints,
  getFullPoints,
  getPointsForCenetrRoofPart,
  HEIGHT_DRAW_AREA,
  WIDTH_DRAW_AREA,
} from "./RoofPart";

// Przełożenie punktów na kolekcję dla komponentu Line
const getPointsForLineComponent = (pointIds: any[], roofPoints: any[]) => {
  let arr = [];
  for (const id of pointIds) {
    const p = roofPoints.find((x) => x.id === id);
    if (p) {
      arr.push(p.x);
      arr.push(p.y);
    }
  }
  return arr;
};

type Props = {
  dormerHole: IReportDormerHole;
  points: IReportRoofPoint[];
  edges: IReportRoofEdge[];
  basic: boolean;
};

const DormerHoleComponent: FC<Props> = ({ dormerHole, points, edges, basic }) => {
  return (
    <Container>
      <Group>
        {points.length > 0 && (
          <Line
            points={getPointsForLineComponent(dormerHole.pointIds, points)}
            closed={true}
            stroke="#aaaaaa"
            strokeWidth={0.5}
          />
        )}
      </Group>
    </Container>
  );
};
export default DormerHoleComponent;

const Container = styled(Group)``;
