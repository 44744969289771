import React, { useState, useEffect, useLayoutEffect, useRef, FC } from "react";
import styled from "styled-components";
import logoFooter from "../assets/images/logo-footer.png";
import iconRoofSum from "../assets/images/icon-roof-sum.png";
import { Stage, Layer } from "react-konva";
import RoofPart from "../components/RoofPart";
import HoleComponent from "../components/HoleComponent";
import Konva from "konva";
import PageHeader from "../components/PageHeader";
import PageFooter from "../components/PageFooter";
import { Col, Row } from "antd";
import {
  IReportChimney,
  IReportDormerHole,
  IReportReturnModel,
  IReportRoofPlane,
  IReportWindowHole,
} from "../Model";
import { showMoney } from "../Helper";
import WindowHoleComponent from "../components/WindowHoleComponent";
import ChimneyComponent from "../components/ChimneyComponent";
import DormerHoleComponent from "../components/DormerHoleComponent";

const HEIGHT_DRAW_AREA = 530;
const WIDTH_DRAW_AREA = 690;

export const getCorrectedPoints = (points: any[], width: number, height: number, margin: any) => {
  var pl = getMovedPointsToStart(points, margin);
  var scale = getScaleToDraw(pl, width, height);

  return getScaledPoints(pl, scale);
};

export const getScaleToDraw = (points: any[], width: number, height: number) => {
  // Pobieramy maksymalną szerokość rysunku
  const maxXPoints = Math.max(...points.map((o) => o.x));

  //Pobieramy maskymalną wysokość rysunku
  const maxYPoints = Math.max(...points.map((o) => o.y));

  const widthRatio = width / (maxXPoints + 5);
  const heightRatio = height / (maxYPoints + 5);

  const scale = Math.min(widthRatio, heightRatio);

  return scale;
};

export const getScaledPoints = (points: any[], scale: any) => {
  const result = [];
  for (const p of points) {
    result.push({ ...p, x: p.x * scale, y: p.y * scale });
  }

  return result;
};

export const getMovedPointsToStart = (points: any[], margin: any) => {
  const minX = Math.min(...points.map((o: any) => o.x));
  const minY = Math.min(...points.map((o: any) => o.y));
  let distToMoveX = -1 * (minX - margin);
  let distToMoveY = -1 * (minY - margin);

  const result = [];
  for (const p of points) {
    result.push({ ...p, x: p.x + distToMoveX, y: p.y + distToMoveY });
  }
  return result;
};

export const getFullAddress = (street: any, houseNumber: any, zipCode: any, city: any) => {
  return `${street} ${houseNumber}, ${zipCode} ${city}`;
};

type Props = {
  report: IReportReturnModel;
};

const Page1: FC<Props> = ({ report }) => {
  const stage = useRef<Konva.Stage>(null); // Referencja do obszaru
  const stageParent = useRef<HTMLDivElement>(null); // Referencja do rodzica stage
  const [dimStageParent, setDimStageParent] = useState({ width: 0, height: 0 }); // wymiary parent stage

  const [correctedRoofPoints, setCorrectedRoofPoints] = useState<any[]>([]);

  useEffect(() => {
    if (report) {
      if (report.drawingData.points) {
        setCorrectedRoofPoints(
          getCorrectedPoints(report.drawingData.points, WIDTH_DRAW_AREA, HEIGHT_DRAW_AREA, 5)
        );
      }
    }
  }, [report, dimStageParent]);

  // Przypisanie wymiarów parent stage
  useLayoutEffect(() => {
    if (stageParent.current) {
      setDimStageParent({
        width: stageParent.current.offsetWidth,
        height: stageParent.current.offsetHeight,
      });
    }
  }, []);

  const getDrawWidth = () => {
    const maxXPoints = Math.max(...correctedRoofPoints.map((o) => o.x));
    return maxXPoints;
  };

  const getXMargin = () => {
    const drawWidth = getDrawWidth();
    if (drawWidth > 0) {
      return (WIDTH_DRAW_AREA - drawWidth) / 2;
    }
    return 0;
  };

  const getYMargin = () => {
    const drawHeight = Math.max(...correctedRoofPoints.map((o) => o.y));
    if (drawHeight > 0) {
      return (HEIGHT_DRAW_AREA - drawHeight) / 2;
    }
    return 0;
  };

  const getFullAreaRoof = () => {
    let area = 0;
    area = report.roofArea || 0;

    return area;
  };

  return (
    <PageContainer>
      <PageWrapper>
        <PageHeader report={report} />

        <PageContent>
          <PageContentContent>
            <ImageSection>
              <ImageDrawContainer>
                <ImageDraw ref={stageParent}>
                  <Stage ref={stage} width={WIDTH_DRAW_AREA} height={HEIGHT_DRAW_AREA}>
                    <Layer offsetX={-1 * getXMargin()} offsetY={-1 * getYMargin()}>
                      {/* Połacie na rysunku */}
                      {report.drawingData.planes &&
                        report.drawingData.planes.map((plane: IReportRoofPlane, i: number) => {
                          return (
                            <RoofPart
                              key={plane.id}
                              plane={plane}
                              points={correctedRoofPoints}
                              edges={report.drawingData.edges}
                              basic={true}
                            />
                          );
                        })}

                      {/* Okna na rysunku */}
                      {report.drawingData.windowHoles &&
                        report.drawingData.windowHoles.map((wh: IReportWindowHole, i: number) => {
                          return (
                            <WindowHoleComponent
                              key={wh.id}
                              windowHole={wh}
                              points={correctedRoofPoints}
                              edges={report.drawingData.edges}
                              basic={true}
                            />
                          );
                        })}

                      {/* Otwory lukarn na rysunku */}
                      {report.drawingData.dormerHoles &&
                        report.drawingData.dormerHoles.map((dh: IReportDormerHole, i: number) => {
                          return (
                            <DormerHoleComponent
                              key={dh.id}
                              dormerHole={dh}
                              points={correctedRoofPoints}
                              edges={report.drawingData.edges}
                              basic={true}
                            />
                          );
                        })}

                      {/* Kominy na rysunku */}
                      {report.drawingData.chimneys &&
                        report.drawingData.chimneys.map((h: IReportChimney, i: number) => {
                          return (
                            <ChimneyComponent
                              key={h.id}
                              chimney={h}
                              points={correctedRoofPoints}
                              edges={report.drawingData.edges}
                              basic={true}
                            />
                          );
                        })}
                    </Layer>
                  </Stage>
                </ImageDraw>
              </ImageDrawContainer>

              <InfoSection>
                <InfoSectionArea span={8}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      background: "#F7F7F7",
                      width: "120px",
                      height: "100%",
                      borderRadius: "8px",
                      boxSizing: "border-box",
                      padding: "18px",
                    }}
                  >
                    <img src={iconRoofSum} style={{ display: "block", height: "auto", maxWidth: "100%" }} />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "flex-start",
                      height: "100%",
                      width: "100%",
                    }}
                  >
                    Powierzchnia dachu:
                    <br />
                    <b>{getFullAreaRoof().toFixed(2)} m2</b>
                  </div>
                </InfoSectionArea>
                <InfoSectionPrincing span={16}>
                  <PrincingTable>
                    <PrincingTableHeader>
                      <PrincingTableItemColumn span={10} style={{ fontSize: "12px" }}></PrincingTableItemColumn>
                      <PrincingTableItemColumn span={7} style={{ fontSize: "12px" }}>
                        Netto
                      </PrincingTableItemColumn>
                      <PrincingTableItemColumn
                        span={7}
                        style={{ justifyContent: "flex-end", fontSize: "12px", paddingRight: "0px" }}
                      >
                        Brutto ({report.vat}% VAT)
                      </PrincingTableItemColumn>
                    </PrincingTableHeader>
                    <PrincingTableItem style={{ color: "#7B695F" }}>
                      <PrincingTableItemColumn
                        span={10}
                        style={{ justifyContent: "flex-start", fontSize: "16px" }}
                      >
                        Cena katalogowa
                      </PrincingTableItemColumn>
                      <PrincingTableItemColumn span={7} style={{ fontSize: "16px" }}>
                        {showMoney(report.totalNettCatalogPrice)} zł
                      </PrincingTableItemColumn>
                      <PrincingTableItemColumn span={7} style={{ justifyContent: "flex-end", fontSize: "16px" }}>
                        {showMoney(report.totalCatalogPrice)} zł
                      </PrincingTableItemColumn>
                    </PrincingTableItem>
                    <PrincingTableItem style={{ color: "#006BCC" }}>
                      <PrincingTableItemColumn
                        span={10}
                        style={{ justifyContent: "flex-start", fontSize: "16px" }}
                      >
                        Cena końcowa
                      </PrincingTableItemColumn>
                      <PrincingTableItemColumn span={7} style={{ fontSize: "16px" }}>
                        {showMoney(report.totalNettPrice)} zł
                      </PrincingTableItemColumn>
                      <PrincingTableItemColumn span={7} style={{ justifyContent: "flex-end", fontSize: "16px" }}>
                        {showMoney(report.totalPrice)} zł
                      </PrincingTableItemColumn>
                    </PrincingTableItem>
                    <PrincingTableItem style={{ borderTop: "1px solid #848080", color: "#FF6752" }}>
                      <PrincingTableItemColumn
                        span={10}
                        style={{ justifyContent: "flex-start", fontSize: "16px" }}
                      >
                        Oszczędzasz
                      </PrincingTableItemColumn>
                      <PrincingTableItemColumn span={7} style={{ fontSize: "16px" }}>
                        {showMoney(report.totalNettSaving)} zł
                      </PrincingTableItemColumn>
                      <PrincingTableItemColumn span={7} style={{ justifyContent: "flex-end", fontSize: "16px" }}>
                        {showMoney(report.totalSaving)} zł
                      </PrincingTableItemColumn>
                    </PrincingTableItem>
                  </PrincingTable>
                </InfoSectionPrincing>
              </InfoSection>
            </ImageSection>
            <BottomSection>
              <BottomSectionContent>
                <BottomSectionContentRow>
                  Ofertę sporządził: <span style={{ fontWeight: "500" }}>{report.userName}</span>
                </BottomSectionContentRow>
                <BottomSectionContentRow>
                  tel. <span style={{ fontWeight: "500" }}>{report.userPhone}</span>
                </BottomSectionContentRow>
                <BottomSectionContentRow>
                  E-mail: <span style={{ fontWeight: "500" }}>{report.userEmail}</span>
                </BottomSectionContentRow>
              </BottomSectionContent>
            </BottomSection>
          </PageContentContent>
        </PageContent>

        <PageFooter />
      </PageWrapper>
    </PageContainer>
  );
};
export default Page1;

const PageContainer = styled.div`
  display: table;
  margin: 0 auto;
  width: 794px !important;
  height: 1123px !important;
  background: #ffffff;
`;

const PageWrapper = styled.div`
  padding: 28px 36px 28px 36px;
  height: calc(1123px - 56px) !important;
`;

const PageContent = styled.div`
  height: 847px !important;
`;

const PageContentContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

// Kontener na rysunek
const ImageSection = styled.div`
  display: flex;
  flex-direction: column;

  height: 81%;
  width: 100%;

  margin-top: 5%;

  /* border: 1px solid #006bcc; */

  /* background-color: #ac7575; */
`;

const ImageDrawContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin: auto; */
  height: calc(100% - 100px);
  width: 100%;

  /* background-color: #362b2b; */
`;

const ImageDraw = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  /* background-color: #e4dcdc; */
`;

const InfoSection = styled(Row)`
  display: flex;
  align-items: flex-end;
  height: 100px;
  width: 100%;
`;

const InfoSectionArea = styled(Col)`
  display: flex;
  height: 100%;
  width: 100%;
  background-color: #f7f7f7;
  border-radius: 8px;
`;

const InfoSectionPrincing = styled(Col)`
  display: flex;
  align-items: flex-end;
  height: 100%;
  width: 100%;
`;

const BottomSection = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  height: 90px;
  color: #848080;
  margin-top: 16px;
  /* background-color: #7c7c7c; */
`;

const BottomSectionContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 16px;
`;

const BottomSectionContentRow = styled.div``;

const PrincingTable = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-left: 16px;
  padding: 0px 16px 0px 16px;
  border-radius: 8px;
  background: #f7f7f7;
`;

const PrincingTableHeader = styled.div`
  display: flex;
  width: 100%;
  margin-top: 8px;
  color: #7b695f;
  border-bottom: 1px solid #848080;
  font-weight: 500;
`;

const PrincingTableItem = styled(Row)`
  display: flex;
  width: 100%;
  font-weight: 500;
`;
const PrincingTableItemColumn = styled(Col)`
  display: flex;
  justify-content: center;
  width: 100%;
  text-align: center;
`;
