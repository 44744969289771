import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { Line, Group, Text } from "react-konva";
import { IReportChimney, IReportRoofEdge, IReportRoofPoint, IReportWindowHole } from "../Model";
import {
  getCorrectedPoints,
  getFullPoints,
  getPointsForCenetrRoofPart,
  HEIGHT_DRAW_AREA,
  WIDTH_DRAW_AREA,
} from "./RoofPart";

// Przełożenie punktów na kolekcję dla komponentu Line
const getPointsForLineComponent = (pointIds: any[], roofPoints: any[]) => {
  let arr = [];
  for (const id of pointIds) {
    const p = roofPoints.find((x) => x.id === id);
    if (p) {
      arr.push(p.x);
      arr.push(p.y);
    }
  }
  return arr;
};

type Props = {
  chimney: IReportChimney;
  points: IReportRoofPoint[];
  edges: IReportRoofEdge[];
  basic: boolean;
};

const ChimneyComponent: FC<Props> = ({ chimney, points, edges, basic }) => {
  return (
    <Container>
      <Group>
        {points.length > 0 && (
          <Line
            points={getPointsForLineComponent(chimney.pointIds, points)}
            fill={"#c2c2c2"}
            closed={true}
            stroke="#6b6b6b"
            strokeWidth={1}
          />
        )}

        {chimney.pointIds.length > 0 && points.length > 0 && (
          <Text
            fill="#969696"
            fontSize={10}
            x={getPointsForCenetrRoofPart(getFullPoints(chimney.pointIds, points)).x}
            y={getPointsForCenetrRoofPart(getFullPoints(chimney.pointIds, points)).y}
            offsetX={3}
            offsetY={3}
            text="K"
          />
        )}
      </Group>
    </Container>
  );
};
export default ChimneyComponent;

const Container = styled(Group)``;
